import { ClaimType } from '../types/claim.types';
import type { ClaimData } from '../types/claim.types';
import { defaultTrackingBaseUrl } from '../constants';

/*
  same functionality in ~/apps/valet/helpers/claims.helper.ts

  TODO: merge in the future
*/

/*
  Claim is considered in the item level if:
  * claimType is `MULTIPLE`
  * claim has multiple values for `claimItem.claimType`
*/

export function isClaimItemLevel(claim: ClaimData) {
  if (String(claim.claimType).toUpperCase() === ClaimType.MULTIPLE) {
    return true;
  }

  const uniqueClaimTypes = new Set(claim.claimItems.map(item => item.claimType));
  return uniqueClaimTypes.size > 1;
}

export function isClaimOrderLevel(claim: ClaimData) {
  return !isClaimItemLevel(claim);
}

export const buildTrackingLink = ({
  trackingUrl,
  trackingNumber,
}: {
  trackingUrl?: string;
  trackingNumber?: string;
}) => {
  return trackingUrl ? trackingUrl : defaultTrackingBaseUrl + `/${trackingNumber}`;
};
