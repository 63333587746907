import countryListJs from 'country-list-js';

export type Province = { name: string; short?: string; alias?: string[] | null };
export type CountryDetails = {
  name: string;
  continent: string;
  region: string;
  capital: string;
  currency: { code: string; symbol: string; decimal: string };
  dialing_code: string;
  code: { iso2: string; iso3: string };
  provinces: Province[] | undefined;
};

export const usStates = countryListJs.findByIso2('US').provinces as Province[];
export const countries = countryListJs.ls('name') as string[];

export function getProvincesByCountry(country: string | undefined) {
  const countryDetails: CountryDetails | undefined = countryListJs.findByName(country);

  return (countryDetails?.provinces ?? []).map(province => {
    const regexAtleastOneEnglishLetter = /^(?=[^A-Za-z]*[A-Za-z])[ -~]*$/;
    const label = regexAtleastOneEnglishLetter.test(province.name)
      ? province.name
      : (province.alias?.[0] ?? province.name);

    const value = province.short ?? province.name;

    return { label, value };
  });
}

export function getCountryByCode(code: string): CountryDetails | CountryDetails[] | undefined {
  return countryListJs.findByIso2(code) || countryListJs.findByIso3(code);
}
