import { default as claim_45received_46specjxtwGwVVBWMeta } from "/app/apps/concierge/src/pages/claim-received.spec.js?macro=true";
import { default as claim_45receivedtA7zrOAMLAMeta } from "/app/apps/concierge/src/pages/claim-received.vue?macro=true";
import { default as index_46specIWek603zjSMeta } from "/app/apps/concierge/src/pages/index.spec.js?macro=true";
import { default as indexRcFS2qQuICMeta } from "/app/apps/concierge/src/pages/index.vue?macro=true";
import { default as issues_46specHVTV4dsJ3QMeta } from "/app/apps/concierge/src/pages/issues.spec.js?macro=true";
import { default as index_46specM8vyosDx74Meta } from "/app/apps/concierge/src/pages/issues/index.spec.js?macro=true";
import { default as indexmRa9T7Az1zMeta } from "/app/apps/concierge/src/pages/issues/index.vue?macro=true";
import { default as items_46speciTSJnQNVNtMeta } from "/app/apps/concierge/src/pages/issues/items.spec.js?macro=true";
import { default as itemsSebbLmbLe1Meta } from "/app/apps/concierge/src/pages/issues/items.vue?macro=true";
import { default as shipment_46specoNQRKC5NOdMeta } from "/app/apps/concierge/src/pages/issues/shipment.spec.js?macro=true";
import { default as details_46specicaZjB1zm8Meta } from "/app/apps/concierge/src/pages/issues/shipment/details.spec.js?macro=true";
import { default as detailsBKlJcXYpm2Meta } from "/app/apps/concierge/src/pages/issues/shipment/details.vue?macro=true";
import { default as index_46specQ7dha50LT7Meta } from "/app/apps/concierge/src/pages/issues/shipment/index.spec.js?macro=true";
import { default as index7x3su0Y6wWMeta } from "/app/apps/concierge/src/pages/issues/shipment/index.vue?macro=true";
import { default as shipmentIdbapodMXWMeta } from "/app/apps/concierge/src/pages/issues/shipment.vue?macro=true";
import { default as issuesePjfeQqLO9Meta } from "/app/apps/concierge/src/pages/issues.vue?macro=true";
import { default as index_46specRRjZ1lib2YMeta } from "/app/apps/concierge/src/pages/multiple-shipments/index.spec.js?macro=true";
import { default as indexa09j5lhPCzMeta } from "/app/apps/concierge/src/pages/multiple-shipments/index.vue?macro=true";
import { default as not_45shipped_46spec3ZzDbT0zKyMeta } from "/app/apps/concierge/src/pages/not-shipped.spec.js?macro=true";
import { default as not_45shipped3tVt0LXiCaMeta } from "/app/apps/concierge/src/pages/not-shipped.vue?macro=true";
import { default as _91token_93nggH91JHeqMeta } from "/app/apps/concierge/src/pages/print/[token].vue?macro=true";
import { default as process_46specYX49P39FUFMeta } from "/app/apps/concierge/src/pages/process.spec.js?macro=true";
import { default as index_46specx6QOGZy4mBMeta } from "/app/apps/concierge/src/pages/process/index.spec.js?macro=true";
import { default as indexDjt1Hc7AUJMeta } from "/app/apps/concierge/src/pages/process/index.vue?macro=true";
import { default as processmYDWhIdThyMeta } from "/app/apps/concierge/src/pages/process.vue?macro=true";
import { default as resolution_46spec1xnoSwkbwmMeta } from "/app/apps/concierge/src/pages/resolution.spec.js?macro=true";
import { default as index_46spec4yeTcriTjyMeta } from "/app/apps/concierge/src/pages/resolution/index.spec.js?macro=true";
import { default as indexULyiQkaWFCMeta } from "/app/apps/concierge/src/pages/resolution/index.vue?macro=true";
import { default as review_46specN0kD768blVMeta } from "/app/apps/concierge/src/pages/resolution/review.spec.js?macro=true";
import { default as review3w0oaWGzkPMeta } from "/app/apps/concierge/src/pages/resolution/review.vue?macro=true";
import { default as resolutionIN9pafKanIMeta } from "/app/apps/concierge/src/pages/resolution.vue?macro=true";
import { default as return_46specxJbTydzXYkMeta } from "/app/apps/concierge/src/pages/return.spec.js?macro=true";
import { default as start_46specX3ZHLI7AbSMeta } from "/app/apps/concierge/src/pages/return/start.spec.js?macro=true";
import { default as start4eFoBcOHUmMeta } from "/app/apps/concierge/src/pages/return/start.vue?macro=true";
import { default as returne34PxO4rOMMeta } from "/app/apps/concierge/src/pages/return.vue?macro=true";
import { default as start_46specU0cur5m9IMMeta } from "/app/apps/concierge/src/pages/start.spec.js?macro=true";
import { default as startv1reK3iRXPMeta } from "/app/apps/concierge/src/pages/start.vue?macro=true";
import { default as too_45late_46specWRkpk2a9OMMeta } from "/app/apps/concierge/src/pages/too-late.spec.js?macro=true";
import { default as too_45lateQlqdKhfNyrMeta } from "/app/apps/concierge/src/pages/too-late.vue?macro=true";
import { default as too_45soon_46specx4dErUfNnTMeta } from "/app/apps/concierge/src/pages/too-soon.spec.js?macro=true";
import { default as too_45soonafT68D1oYVMeta } from "/app/apps/concierge/src/pages/too-soon.vue?macro=true";
import { default as _91token_93qp7mFPTkA4Meta } from "/app/apps/concierge/src/pages/view/[token].vue?macro=true";
export default [
  {
    name: "claim-received.spec___en",
    path: "/claim-received.spec",
    component: () => import("/app/apps/concierge/src/pages/claim-received.spec.js").then(m => m.default || m)
  },
  {
    name: "claim-received___en",
    path: "/claim-received",
    component: () => import("/app/apps/concierge/src/pages/claim-received.vue").then(m => m.default || m)
  },
  {
    name: "index.spec___en",
    path: "/index.spec",
    component: () => import("/app/apps/concierge/src/pages/index.spec.js").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/apps/concierge/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "issues.spec___en",
    path: "/issues.spec",
    component: () => import("/app/apps/concierge/src/pages/issues.spec.js").then(m => m.default || m)
  },
  {
    name: issuesePjfeQqLO9Meta?.name,
    path: "/issues",
    component: () => import("/app/apps/concierge/src/pages/issues.vue").then(m => m.default || m),
    children: [
  {
    name: "issues-index.spec___en",
    path: "index.spec",
    component: () => import("/app/apps/concierge/src/pages/issues/index.spec.js").then(m => m.default || m)
  },
  {
    name: "issues___en",
    path: "",
    component: () => import("/app/apps/concierge/src/pages/issues/index.vue").then(m => m.default || m)
  },
  {
    name: "issues-items.spec___en",
    path: "items.spec",
    component: () => import("/app/apps/concierge/src/pages/issues/items.spec.js").then(m => m.default || m)
  },
  {
    name: "issues-items___en",
    path: "items",
    component: () => import("/app/apps/concierge/src/pages/issues/items.vue").then(m => m.default || m)
  },
  {
    name: "issues-shipment.spec___en",
    path: "shipment.spec",
    component: () => import("/app/apps/concierge/src/pages/issues/shipment.spec.js").then(m => m.default || m)
  },
  {
    name: shipmentIdbapodMXWMeta?.name,
    path: "shipment",
    component: () => import("/app/apps/concierge/src/pages/issues/shipment.vue").then(m => m.default || m),
    children: [
  {
    name: "issues-shipment-details.spec___en",
    path: "details.spec",
    component: () => import("/app/apps/concierge/src/pages/issues/shipment/details.spec.js").then(m => m.default || m)
  },
  {
    name: "issues-shipment-details___en",
    path: "details",
    component: () => import("/app/apps/concierge/src/pages/issues/shipment/details.vue").then(m => m.default || m)
  },
  {
    name: "issues-shipment-index.spec___en",
    path: "index.spec",
    component: () => import("/app/apps/concierge/src/pages/issues/shipment/index.spec.js").then(m => m.default || m)
  },
  {
    name: "issues-shipment___en",
    path: "",
    component: () => import("/app/apps/concierge/src/pages/issues/shipment/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "multiple-shipments-index.spec___en",
    path: "/multiple-shipments/index.spec",
    component: () => import("/app/apps/concierge/src/pages/multiple-shipments/index.spec.js").then(m => m.default || m)
  },
  {
    name: "multiple-shipments___en",
    path: "/multiple-shipments",
    component: () => import("/app/apps/concierge/src/pages/multiple-shipments/index.vue").then(m => m.default || m)
  },
  {
    name: "not-shipped.spec___en",
    path: "/not-shipped.spec",
    component: () => import("/app/apps/concierge/src/pages/not-shipped.spec.js").then(m => m.default || m)
  },
  {
    name: "not-shipped___en",
    path: "/not-shipped",
    component: () => import("/app/apps/concierge/src/pages/not-shipped.vue").then(m => m.default || m)
  },
  {
    name: "print-token___en",
    path: "/print/:token()",
    component: () => import("/app/apps/concierge/src/pages/print/[token].vue").then(m => m.default || m)
  },
  {
    name: "process.spec___en",
    path: "/process.spec",
    component: () => import("/app/apps/concierge/src/pages/process.spec.js").then(m => m.default || m)
  },
  {
    name: processmYDWhIdThyMeta?.name,
    path: "/process",
    component: () => import("/app/apps/concierge/src/pages/process.vue").then(m => m.default || m),
    children: [
  {
    name: "process-index.spec___en",
    path: "index.spec",
    component: () => import("/app/apps/concierge/src/pages/process/index.spec.js").then(m => m.default || m)
  },
  {
    name: "process___en",
    path: "",
    component: () => import("/app/apps/concierge/src/pages/process/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "resolution.spec___en",
    path: "/resolution.spec",
    component: () => import("/app/apps/concierge/src/pages/resolution.spec.js").then(m => m.default || m)
  },
  {
    name: resolutionIN9pafKanIMeta?.name,
    path: "/resolution",
    component: () => import("/app/apps/concierge/src/pages/resolution.vue").then(m => m.default || m),
    children: [
  {
    name: "resolution-index.spec___en",
    path: "index.spec",
    component: () => import("/app/apps/concierge/src/pages/resolution/index.spec.js").then(m => m.default || m)
  },
  {
    name: "resolution___en",
    path: "",
    component: () => import("/app/apps/concierge/src/pages/resolution/index.vue").then(m => m.default || m)
  },
  {
    name: "resolution-review.spec___en",
    path: "review.spec",
    component: () => import("/app/apps/concierge/src/pages/resolution/review.spec.js").then(m => m.default || m)
  },
  {
    name: "resolution-review___en",
    path: "review",
    component: () => import("/app/apps/concierge/src/pages/resolution/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "return.spec___en",
    path: "/return.spec",
    component: () => import("/app/apps/concierge/src/pages/return.spec.js").then(m => m.default || m)
  },
  {
    name: "return___en",
    path: "/return",
    component: () => import("/app/apps/concierge/src/pages/return.vue").then(m => m.default || m),
    children: [
  {
    name: "return-start.spec___en",
    path: "start.spec",
    component: () => import("/app/apps/concierge/src/pages/return/start.spec.js").then(m => m.default || m)
  },
  {
    name: "return-start___en",
    path: "start",
    component: () => import("/app/apps/concierge/src/pages/return/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "start.spec___en",
    path: "/start.spec",
    component: () => import("/app/apps/concierge/src/pages/start.spec.js").then(m => m.default || m)
  },
  {
    name: "start___en",
    path: "/start",
    component: () => import("/app/apps/concierge/src/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "too-late.spec___en",
    path: "/too-late.spec",
    component: () => import("/app/apps/concierge/src/pages/too-late.spec.js").then(m => m.default || m)
  },
  {
    name: "too-late___en",
    path: "/too-late",
    component: () => import("/app/apps/concierge/src/pages/too-late.vue").then(m => m.default || m)
  },
  {
    name: "too-soon.spec___en",
    path: "/too-soon.spec",
    component: () => import("/app/apps/concierge/src/pages/too-soon.spec.js").then(m => m.default || m)
  },
  {
    name: "too-soon___en",
    path: "/too-soon",
    component: () => import("/app/apps/concierge/src/pages/too-soon.vue").then(m => m.default || m)
  },
  {
    name: "view-token___en",
    path: "/view/:token()",
    component: () => import("/app/apps/concierge/src/pages/view/[token].vue").then(m => m.default || m)
  }
]