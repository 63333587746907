export const RESHIP = 'RESHIP';
export const REFUND = 'REFUND';
export const CUSTOM = 'CUSTOM';
export const STORE_CREDIT = 'STORE_CREDIT';
export const APPROVED = 'APPROVED';
export const DENIED = 'DENIED';
export const FLAT_RATE = 'FLAT_RATE';
export const ClaimDocumentType = {
  SIGNATURE: 'SIGNATURE',
  ACKNOWLEDGEMENT: 'ACKNOWLEDGEMENT',
} as const;
export const defaultTrackingBaseUrl = 'https://www.aftership.com/track';
