export const LightTheme = {
  danger: {
    100: '#FEF1F2',
    700: '#E02D3C',
    800: '#BA2532',
    900: '#981B25',
  },
  primary: {
    100: '#F0F5FF',
    700: '#5B1FD3',
    800: '#4818A5',
    900: '#341278',
  },
  success: {
    100: '#EDFDF8',
    700: '#08875D',
    800: '#04724D',
    900: '#066042',
  },
  warning: {
    100: '#FFF8EB',
    700: '#B25E09',
    800: '#96530F',
    900: '#80460D',
  },
  neutral: {
    white: '#FFFFFF',
    100: '#F8F9FC',
    200: '#F1F3F9',
    300: '#E1E6EF',
    700: '#3F444D',
    800: '#23272F',
    900: '#1B1F27',
  },
  secondary: {
    100: '#F8F5FF',
    700: '#8B54F7',
  },
  typography: {
    primary: '#1D2433',
    secondary: 'rgba(29, 36, 51, 0.8)',
    disabled: 'rgba(29, 36, 51, 0.65)',
  },
};

export const DarkTheme = {
  danger: {
    100: '#981B25',
    700: '#FCA6AD',
    800: '#F8727D',
    900: '#EF4352',
  },
  primary: {
    100: '#341278',
    700: '#9B9BFD',
    800: '#8383FB',
    900: '#6C6CF9',
  },
  success: {
    100: '#066042',
    700: '#EDFDF8',
    800: '#04724D',
    900: '#066042',
  },
  warning: {
    100: '#80460D',
    700: '#FDCF72',
    800: '#FBBB3C',
    900: '#DB7712',
  },
  neutral: {
    white: '#1B1F27',
    100: '#23272F',
    200: '#2C313A',
    300: '#3F444D',
    700: '#E1E6EF',
    800: '#F1F3F9',
    900: '#F8F9FC',
  },
  secondary: {
    100: '#F8F5FF',
    700: '#8B54F7',
  },
  typography: {
    primary: 'rgba(255, 255, 255, 0.925)',
    secondary: 'rgba(255, 255, 255, 0.75)',
    disabled: 'rgba(255, 255, 255, 0.60)',
  },
};

export const Colors = {
  danger: {
    100: `var(--danger-100, ${LightTheme.danger[100]})`,
    700: `var(--danger-700, ${LightTheme.danger[700]})`,
    800: `var(--danger-800, ${LightTheme.danger[800]})`,
    900: `var(--danger-900, ${LightTheme.danger[900]})`,
  },
  primary: {
    100: `var(--primary-100, ${LightTheme.primary[100]})`,
    700: `var(--primary-700, ${LightTheme.primary[700]})`,
    800: `var(--primary-800, ${LightTheme.primary[800]})`,
    900: `var(--primary-900, ${LightTheme.primary[900]})`,
  },
  success: {
    100: `var(--success-100, ${LightTheme.success[100]})`,
    700: `var(--success-700, ${LightTheme.success[700]})`,
    800: `var(--success-800, ${LightTheme.success[800]})`,
    900: `var(--success-900, ${LightTheme.success[900]})`,
  },
  warning: {
    100: `var(--warning-100, ${LightTheme.warning[100]})`,
    700: `var(--warning-700, ${LightTheme.warning[700]})`,
    800: `var(--warning-800, ${LightTheme.warning[800]})`,
    900: `var(--warning-900, ${LightTheme.warning[900]})`,
  },
  neutral: {
    white: `var(--neutral-white, ${LightTheme.neutral.white})`,
    100: `var(--neutral-100, ${LightTheme.neutral[100]})`,
    200: `var(--neutral-200, ${LightTheme.neutral[200]})`,
    300: `var(--neutral-300, ${LightTheme.neutral[300]})`,
    700: `var(--neutral-700, ${LightTheme.neutral[700]})`,
    800: `var(--neutral-800, ${LightTheme.neutral[800]})`,
    900: `var(--neutral-900, ${LightTheme.neutral[900]})`,
  },
  secondary: {
    100: `var(--secondary-100, ${LightTheme.secondary[100]})`,
    700: `var(--secondary-700, ${LightTheme.secondary[700]})`,
  },
  typography: {
    primary: `var(--typography-primary, ${LightTheme.typography.primary})`,
    secondary: `var(--typography-secondary, ${LightTheme.typography.secondary})`,
    disabled: `var(--typography-disabled, ${LightTheme.typography.disabled})`,
  },
};

export const FontFamily = {
  Primary: 'Inter',
  Heading: 'Faktum',
};

export const FontSizes = {
  '2xs': '11px',
  xsm: '13px',
  sm: '14px',
  base: '16px',
  lg: '18px',
  xl: '20px',
  '2xl': '24px',
  '3xl': '30px',
}
