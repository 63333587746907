import type { Order } from '../types/order.types';

// TODO: remove duplicate enum in ./common.interface.ts
export enum ClaimType {
  STOLEN = 'STOLEN',
  DAMAGED = 'DAMAGED',
  ORDER_DELIVERED_NOT_RECEIVED = 'ORDER_DELIVERED_NOT_RECEIVED',
  DEFECTIVE_ITEM = 'DEFECTIVE_ITEM',
  WRONG_ITEM = 'WRONG_ITEM',
  MISSING_ITEM = 'MISSING_ITEM',

  RETURNED_TO_SENDER = 'RETURNED_TO_SENDER',
  MULTIPLE = 'MULTIPLE',
}

// TODO: remove duplicate enum in ./common.interface.ts
export enum ClaimState {
  NEW = 'NEW',
  OPEN = 'OPEN',
  DISMISSED = 'DISMISSED',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
  RESOLVED = 'RESOLVED',
  ARCHIVED = 'ARCHIVED',
  COMMUNICATING = 'COMMUNICATING',
  WAITING_FOR_CUSTOMER = 'WAITING_FOR_CUSTOMER',
  WAITING_FOR_BRAND = 'WAITING_FOR_BRAND',
  WAITING_FOR_OP = 'WAITING_FOR_OP',
  RESOLVED_BY_BRAND = 'RESOLVED_BY_BRAND',
  TEMPORARILY_DISMISSED = 'TEMPORARILY_DISMISSED',
  CLOSED = 'CLOSED',
}

export enum ResolutionType {
  RESHIP = 'RESHIP',
  REPLACEMENT = 'REPLACEMENT',
  REFUND = 'REFUND',
  CUSTOM = 'CUSTOM',
  EXPIRED30 = 'EXPIRED30',
  EXCHANGE = 'EXCHANGE',
  CREDIT = 'CREDIT',
  STORE_CREDIT = 'STORE_CREDIT',
  RETURN = 'RETURN',
  OTHER = 'OTHER',
  NO_RESPONSE = 'NO_RESPONSE',
  PACKAGE_ARRIVED = 'PACKAGE_ARRIVED',
  CUSTOMER_RESPONDED = 'CUSTOMER_RESPONDED',
  FOLLOW_UP = 'FOLLOW_UP',
  INSUFFICIENT_SHIPPER = 'INSUFFICIENT_SHIPPER',
  RETURN_TO_SENDER = 'RETURN_TO_SENDER',
  NOT_COVERED = 'NOT_COVERED',
  ADDRESS_TYPO = 'ADDRESS_TYPO',
  PHOTO_VERIFIED = 'PHOTO_VERIFIED',
  THRESHOLD_REACHED = 'THRESHOLD_REACHED',
  ALREADY_REFUNDED = 'ALREADY_REFUNDED',
  ALREADY_REPLACED = 'ALREADY_REPLACED',
  POTENTIAL_FRAUD = 'POTENTIAL_FRAUD',
  FLAT_RATE = 'FLAT_RATE',
}

export enum ClaimMessageSenderType {
  customer = 'customer',
  brand = 'brand',
  admin = 'admin',
  automatic = 'automatic',
  system = 'system',
}

export interface ClaimMessage {
  id: string;
  messageContent: string;
  senderName: string;
  senderId: string;
  readAt: Date;
  claimId: string;
  senderType: ClaimMessageSenderType;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface ClaimItem {
  id: string;
  name: string;
  claimId: string;
  discount: number;
  creditStore?: boolean;
  orderId: string;
  sourceItemId: string;
  sourceOrderId: string;
  sourceProductId: string;
  sourceVariantId: string;
  sku: string;
  resolutionRequested: ResolutionType;
  resolutionFinal: ResolutionType;
  isAdditionalItem: boolean;
  imageUrl: string;
  images?: {
    url: string;
  }[];
  videos?: {
    url: string;
  }[];
  selectedOptions?: {
    name: string;
    value: string;
  }[];
  title?: string;
  photo?: string;
  quantity: number;
  price: string;
  resolutionQuantity: number;
  resolutionPercentage: number;
  resolutionFlatAmount: number;
  resolutionId: string;
  claimType: ClaimType;
  details: string;
  orderItem?: Order;
  tax: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface ClaimResolution {
  id: string;
  claimId: string;
  userId: string;
  claimItems: ClaimItem[];
  resolutionType: ResolutionType;
  resolutionDetails: string;
  paidValue: number;
  paidCurrency: string;
  paidOriginalValue: number;
  includeShipping: boolean;
  shippingAmount: number;
  creditStore: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface ClaimData {
  id: string;
  orderId: string;
  storeId: string;
  sourceOrderId: string;
  sourceOrderNumber: string;
  authorName: string;
  authorEmail: string;
  claimDescription: string;
  claimState?: ClaimState;
  claimType: ClaimType;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date | null;
  policyId: string;
  claimItems: ClaimItem[];
  claimMessages: ClaimMessage[];
  claimResolutions: ClaimResolution[];
  claimStoreCredits?: number | null; // TODO: find actual type. Rocky is just guessing
  resolutions?: ClaimResolution[];
}
