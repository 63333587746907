import { createTestingPinia } from '@pinia/testing';
import { shallowMount } from '@vue/test-utils';
import { setActivePinia } from 'pinia';

import Component from './ProcessReturns.vue';

let wrapper;

describe('ProcessReturns', () => {
  beforeEach(() => {
    setActivePinia(
      createTestingPinia({
        initialState: {
          orders: {
            currentOrder: {
              returns: [],
            },
          },
        },
      }),
    );
    wrapper = shallowMount(Component);
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
